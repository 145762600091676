body {
    font-family: "Montserrat";
}

.parallax-container {
    width: 100%;
    height: 2500px;
    overflow: hidden;
    display: grid;
    place-items: center;
  }
  
  .parallax-layer {
    top: 0;
    left: 0;
  }
  
  .parallax-layer-1 {
    transform: translateY(-300px);
    z-index: 0;
    .cover-container {
        overflow: hidden;
        max-height: 1000px;
        .cover {
            width: 100vw;
            filter: brightness(60%);
            object-fit: cover;
            object-position: 0 -200;
        }
    }
  }
  
  .parallax-layer-2 {
    transform: translateY(-700px);

    .hero-text {
        color: #fff;

        &-title {
            font-family: 'League Spartan', arial, helvetica, sans-serif;
            font-size: 80px;
            font-weight: 700;
            text-align: center;
            margin-top: 0;
        }
        &-subtitle {
            font-size: 24px;
            font-weight: 400;
            text-align: center;
            .phone {
                margin-top: 70px;
            }
        }
    }
  }
  
  .parallax-layer-3 {
    z-index: 1;
    display: flex;
    width: 100vw;
    justify-content: flex-start;

    .logo {
        position: absolute;
        top: 30px;
        left: 45px;
        }
    .actions {
        position: absolute;
        top: 30px;
        right: 45px;
        font-size: 25px;
        a {
            text-decoration: none;
            color: #ccc;
        }
    }
  }

  .parallax-layer-4 {
    transform: translateY(-450px);
    .title {
        font-family: 'League Spartan', arial, helvetica, sans-serif;
        font-size: 60px;
        font-weight: 700;
        text-align: center;
        color: rgb(92, 93, 94)
    }
    .flex-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
        padding: 0 10vw;
        .flex-item {
            margin: 0 20px;
            text-align: center;
            width: 24vw;
            &-image {
                margin-bottom: 1.5em;
                img {
                    max-width: 24vw;
                }
            }
            &-title {
                font-size: 22px;
                font-weight: 400;
                margin-bottom: 2.5em;
            }
            li {
                text-align: left;
            }
            &-text {
                letter-spacing: .5px;
            }
        }
    }
    .gallery {
        margin: 50px 0;
        .ant-image {
            height: 300px;
            display: inline-flex;
            overflow: hidden;
            flex-direction: column;
            justify-content: center;
            img {
                vertical-align: middle;
            }
        }
    }
    .contact-container {
        margin: 0 20vw;
        h3 {
            margin: 0 20vw;
            font-family: "Montserrat";
            a {
                text-decoration: none;
                color: rgb(92, 93, 94)
            }
        }
    }
  }

